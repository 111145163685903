import React, { useEffect, useState } from 'react'

import { Page } from '../'
import { Form } from '../../components/form'
import Loading from '../../components/loading'
import { NavInPoint } from '../../components/nav'
import { points as pointsApi, scenarios as scenariosApi } from '../../lib/api'
import checkPoint from '../../lib/check/point'
import { apiCache } from '../../stores'

export const PointTrigger = ({
  match: {
    params: { experienceUid, pointUid, siteUid, trackUid }
  }
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [checks, setChecks] = useState({ nb: 0, warnings: [], errors: [] })
  const [point, setPoint] = useState(apiCache.points.get(pointUid) || {})
  const [scenarios, setScenarios] = useState(apiCache.scenarios.getFromExperience(experienceUid) || [])

  const updatePoint = () =>
    pointsApi
      .get(pointUid)
      .then(async point => point._id && (setChecks(await checkPoint(point)), setPoint(point)))
      .catch(error => console.log('Error getting point', error))

  const updateScenarios = () =>
    scenariosApi
      .getFromExperience(experienceUid)
      .then(scenarios => scenarios.length > 0 && setScenarios(scenarios))
      .catch(error => console.log('Error getting experience scenarios', error))

  // Actualisation des données au chargement de la page.
  useEffect(() => void Promise.all([updatePoint(), updateScenarios()]).finally(() => setIsLoading(false)), [])

  const onSubmit = data => {
    const point = { ...(data || {}), check: data?.validation } // C'est historique. La nouvelle appli utilise "validation", l'ancienne je ne sais pas.
    pointsApi
      .upsert(point)
      .then(async data => data && (setPoint(data), setChecks(await checkPoint(point))))
      .then(updateScenarios)
  }

  return (
    <Page>
      <NavInPoint point={point} />
      <div id="content">
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {checks?.errors?.map(
              (error, index) =>
                error?.text && (
                  <p className="bg-danger" key={`check-error-${index}`}>
                    {error.text}
                  </p>
                )
            )}
            {checks?.warnings?.map(
              (warning, index) =>
                warning?.text && (
                  <p className="bg-warning" key={`check-warning-${index}`}>
                    {warning.text}
                  </p>
                )
            )}
            {checks?.nb > 0 && (
              <p className="bg-success">
                {checks.nb - (checks.warnings?.length || 0) - (checks.errors?.length || 0)} / {checks.nb} vérifications réussies
              </p>
            )}
            <Form
              formPath="points/pointTrigger"
              initialFormData={point}
              onSubmit={onSubmit}
              formContext={{ siteUid, experienceUid, trackUid, scenarios }}
              submitButtonText="Enregistrer"
            />
          </>
        )}
      </div>
      <div id="app-preview" />
    </Page>
  )
}
